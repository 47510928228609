import React from "react"
import { Link } from "gatsby"

import Layout from "/src/components/layout"
import Biogeo from "/src/components/img/biogeo"
import ImgCon from "/src/components/img-con"
import BtnWrap from "/src/components/btn-wrap"
import SEO from "/src/components/seo"

export default ({ data }) => (
  <Layout>
    <SEO title="Biotické interakce (biotické ekologické faktory)" />
    <h1>Biotické interakce (biotické ekologické faktory)</h1>
    <p>Biotick&eacute; interakce (nebo tak&eacute; biotick&eacute; ekologick&eacute; faktory) zahrnuj&iacute; vlivy živ&eacute;ho prostřed&iacute; na organismy a jde o vztahy mezi organismy navz&aacute;jem. Stejně jako <Link to="/biogeografie/ekologicka/ekologicke-faktory">v&scaron;echny ekologick&eacute; faktory</Link> limituj&iacute; v&yacute;skyt organismů a podmiňuj&iacute; tak jejich geografick&eacute; roz&scaron;&iacute;řen&iacute;. Mezi biotick&eacute; interakce organismů patř&iacute; zejm&eacute;na:</p>
    <ul>
    <li><strong>neutralismus</strong></li>
    <li><strong>kompetice</strong></li>
    <li><strong>predace</strong></li>
    <li><strong>parazitismus</strong></li>
    <li><strong>mutualismus</strong></li>
    <li><strong>komens&aacute;lismus</strong></li>
    <li><strong>amens&aacute;lismus</strong></li>
    </ul>
    <hr />
    <h2>Vybran&eacute; biotick&eacute; interakce</h2>
    <h3 id="neutralismus">Neutralismus</h3>
    <p>Neutralismus je jedn&iacute;m z mezidruhov&yacute;ch vztahů, typ biotick&eacute; interakce. Nast&aacute;v&aacute; tehdy, když na sebe populace různ&yacute;ch druhů žij&iacute;c&iacute; v tomt&eacute;ž biotopu na sebe navz&aacute;jem nepůsob&iacute;. To znamen&aacute;, že tyto druhy na sebe nemaj&iacute; negativn&iacute; ani pozitivn&iacute; vliv. Př&iacute;kladem neutralismu může b&yacute;t vztah v&aacute;žky a nutrie ř&iacute;čn&iacute;.</p>
    <h3>Kompetice</h3>
    <p>Kompetice je typ biotick&eacute; interakce, označuj&iacute;c&iacute; konkurenčn&iacute; vztah mezi organismy. Rozli&scaron;ujeme dva z&aacute;kladn&iacute; druhy kompetice:</p>
    <ul>
    <li><strong>v</strong><strong>nitrodruhov&aacute; kompetice</strong> (konkurence mezi jedinci jednoho druhu, např. o potravu, samice atp.)</li>
    <li><strong>mezidruhov&aacute; kompetice</strong> (doch&aacute;z&iacute; k n&iacute; mezi jednotliv&yacute;mi druhy, č&iacute;m maj&iacute; podobněj&scaron;&iacute; ekologickou niku, t&iacute;m vy&scaron;&scaron;&iacute; kompetice o&nbsp;zdroje či prostor)</li>
    </ul>
    <p>Z hlediska podoby boje o zdroje rozli&scaron;ujeme tak&eacute; dal&scaron;&iacute; druhy kompetice:</p>
    <ul>
    <li><strong>exploatačn&iacute; kompetice </strong>(kompetitoři se navz&aacute;jem př&iacute;mo nestřet&aacute;vaj&iacute;, ale t&iacute;m že využ&iacute;vaj&iacute; stejn&yacute; zdroj, jde o boj, kter&yacute; z kompetitorů dok&aacute;že zdroj l&eacute;pe a rychleji využ&iacute;t)</li>
    <li><strong>interferenčn&iacute; kompetice</strong> (kompetitoř&iacute; spolu př&iacute;mo bojuj&iacute; o zdroj)</li>
    </ul>
    <h4>Princip kompetitivn&iacute;ho vyloučen&iacute;</h4>
    <p>Princip kompetitivn&iacute;ho vyloučen&iacute; hovoř&iacute; o tom, že koexistence v&iacute;ce druhů v homogenn&iacute;m prostřed&iacute; vede k dominanci nejsilněj&scaron;&iacute;ho druhu a k vytlačen&iacute; a eliminov&aacute;n&iacute; druhů ostatn&iacute;ch. Na jedn&eacute; lokalitě tak nemohou dlouhodobě koexistovat druhy se shodn&yacute;mi nikami, doch&aacute;z&iacute; na n&iacute; k prostorov&eacute; nebo časov&eacute; seperaci, popř&iacute;padě eliminaci slab&scaron;&iacute;ho druhu.</p>
    <p>Princip kompetitivn&iacute;ho vyloučen&iacute; původně formuloval mikrobiolog Georgii Gause v roce 1934.</p>
    <h3>Predace</h3>
    <p>Predace je biotickou interakc&iacute;, při n&iacute;ž jeden organismus (kořist) je spotřebov&aacute;n organismem druh&yacute;m (pred&aacute;tor, kořistn&iacute;k) za &uacute;čelem z&iacute;sk&aacute;n&iacute; potravy. Podm&iacute;nkou predace je, že kořist mus&iacute; b&yacute;t živ&aacute;. Pred&aacute;tory lze v z&aacute;kladu dělit na dva typy:</p>
    <ul>
    <li><strong>generalist&eacute; </strong>či <strong>polyf&aacute;gov&eacute; </strong>(pred&aacute;toři využ&iacute;vaj&iacute;ci &scaron;irok&yacute; okruh potravn&iacute;ch zdrojů a maj&iacute; k tomu dan&eacute; adaptace)</li>
    <li><strong>specialist&eacute; </strong>či <strong>monof&aacute;gov&eacute;</strong> (pred&aacute;toři specializovan&iacute; na jeden druh či &uacute;zkou skupinu druhů kořisti)</li>
    </ul>
    <h3>Parazitismus</h3>
    <p>Parazitismus je vztah mezi organismy, kdy parazitick&yacute; organismus z&iacute;sk&aacute;v&aacute; živiny z jednoho či v&iacute;ce hostitelsk&yacute;ch organismů. Hostitel z takov&eacute;ho vztahu nem&aacute; prospěch, ale naopak mu &scaron;kod&iacute;. Rozli&scaron;ujeme dva z&aacute;kladn&iacute; typy parazitů:</p>
    <ul>
    <li><strong>holoparazit</strong> (parazit z&iacute;sk&aacute;vaj&iacute;c&iacute; ve&scaron;ker&eacute; l&aacute;tky z hostitele)</li>
    <li><strong>poloparazit</strong> (parazit z&iacute;sk&aacute;vaj&iacute;c&iacute; jen někter&eacute; l&aacute;tky z hostitele, typicky fotosyntetizuj&iacute;c&iacute; poloparazitick&eacute; rostliny jako ochmet evropsk&yacute;)</li>
    </ul>
    <h3>Mutualismus</h3>
    <p>Mutualismus je interakce mezi organismy, kter&aacute; je oboustranně prospě&scaron;n&aacute;. Jin&yacute; n&aacute;zev pro mutualismus může b&yacute;t oboustranně prospě&scaron;n&aacute; symbi&oacute;za. Rozli&scaron;ujeme dva z&aacute;kladn&iacute; druhy mutualismu:</p>
    <ul>
    <li><strong>oblig&aacute;tn&iacute; mutualismus</strong> (druh symbi&oacute;zy při n&iacute;ž mus&iacute; druhy ž&iacute;t společně a vz&aacute;jemně si pak prosp&iacute;vaj&iacute;)</li>
    <li><strong>fakultativn&iacute; mutualismus</strong> (druh symbi&oacute;zy při n&iacute;ž organismy mohou ž&iacute;t samostatně, ale z interakce maj&iacute; oboustrann&yacute; prospěch)</li>
    </ul>
    <h3>Komens&aacute;lismus a amens&aacute;lismus</h3>
    <p><strong>Komens&aacute;lismus </strong>je biotick&aacute; interakce organismů, kdy jeden organismus (komens&aacute;l) m&aacute; v&yacute;hodu, pokud je př&iacute;tomen využ&iacute;van&yacute; druh. Tento využ&iacute;van&yacute; druh je v&scaron;ak touto interakc&iacute; nedotčen.</p>
    <p><strong>Amens&aacute;lismus</strong> je typ mezidruhov&eacute;ho vztahu, při němž jeden druh (inhibitor) negativně působ&iacute; na druh&yacute; druh (amens&aacute;l). Inhibitor je v&scaron;ak touto interakc&iacute; nedotčen.</p>
<hr />
  <ImgCon><Biogeo /><div>
    <h2>Studijn&iacute; materi&aacute;ly Biogeografie</h2>
    <p>T&eacute;ma <strong>Biotické interakce</strong> je souč&aacute;st&iacute; <Link to="/biogeografie/">studijn&iacute;ch materi&aacute;lů Biogeografie</Link>. Tento předmět byl vyučov&aacute;n na <a href="http://geogr.muni.cz">Geografick&eacute;m &uacute;stavu</a> <a href="http://muni.cz">Masarykovy univerzity</a>. Přejděte na rozcestn&iacute;k t&eacute;mat (1.&nbsp;tlač&iacute;tko), nebo si přečtěte předch&aacute;zej&iacute;c&iacute; (2.&nbsp;tlač&iacute;tko) či n&aacute;sleduj&iacute;c&iacute; (3. tlač&iacute;tko) t&eacute;ma.</p>
    <BtnWrap>
    <Link to="/biogeografie/"><button>Rozcestník biogeografie</button></Link>
    <Link to="/biogeografie/ekologicka/abioticke-faktory/"><button className="inv">&larr; Abiotické ekologické faktory</button></Link>
    <Link to="/biogeografie/ekologicka/biogeograficka-pravidla/"><button className="inv">Biogeografická pravidla &rarr;</button></Link>
    </BtnWrap>
  </div></ImgCon><hr />
  </Layout>
)
